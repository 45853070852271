.xml-viewer > div > span > div,
.xml-viewer > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div,
.xml-viewer > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div > span > div {
  white-space: pre-wrap !important;
}