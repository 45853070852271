.auto-size {
  width: auto;
}

.width-75 {
  width: 75px;
  min-width: 75px;
}

.width-96 {
  width: 96px;
  min-width: 96px;
}

.width-100 {
  width: 100px;
  min-width: 100px;
}

.width-125 {
  width: 125px;
  min-width: 125px;
}

.width-155 {
  width: 155px;
  min-width: 155px;
}

.width-159 {
  width: 159px;
  min-width: 159px;
}

.width-180 {
  width: 180px;
  min-width: 180px;
}

.width-200 {
  width: 200px;
  min-width: 200px;
}

.width-232 {
  width: 232px;
  min-width: 232px;
}

.width-242 {
  width: 242px;
  min-width: 242px;
}

.full-size {
  width: 100%;
  min-width: 100%;
}

.min-width-200 {
  min-width: 200px;
}

.max-width-410 {
  max-width: 410px;
}

.max-width-564 {
  max-width: 564px;
}

.height-full {
  height: 100%;
}