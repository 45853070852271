.display-none {
  display: none;
}

.width-carta {
  width: 297mm;
  min-width: 297mm;
}

.width-pos {
  width: 76mm;
  min-width: 76mm;
}

.width-pos-carta {
  width: 220mm;
  min-width: 220mm;
}

.section-carta {
  margin: 2mm !important;
  padding: 2mm !important;
  border: 0.5mm solid #ccc;
  border-radius: 2mm;
}

.container-logo-carta {
  padding: 0;
  align-self: center;
}

.logo-pos-mediano {
  width: 30%;
}

.logo-pos-grande {
  width: 40%;
}

.logo-pos-extra-grande {
  width: 50%;
}

.logo-carta {
  width: 100%;
}

.logo-largo-carta-25 {
  width: 25%;
  margin: 3mm 0 3mm 37.5%;
}

.logo-largo-carta-35 {
  width: 35%;
  margin: 3mm 0 3mm 32.5%;
}

.logo-largo-carta-50 {
  width: 50%;
  margin: 3mm 0 3mm 25%;
}

.texto-clave-carta-01 {
  font-size: 14px;
}

.texto-clave-carta-02 {
  font-size: 16px;
}

.texto-clave-carta-03 {
  font-size: 18px;
}

.texto-carta-01 {
  padding: 0 0 0 2mm;
  font-size: 18px;
}

.texto-carta-02 {
  padding: 0 0 0 2mm;
  font-size: 20px;
}

.texto-carta-03 {
  padding: 0 0 0 2mm;
  font-size: 22px;
}

.texto-medio-carta-01 {
  padding: 0 0 0 2mm;
  font-size: 13px;
}

.texto-medio-carta-02 {
  padding: 0 0 0 2mm;
  font-size: 15px;
}

.texto-medio-carta-03 {
  padding: 0 0 0 2mm;
  font-size: 17px;
}

.texto-linea-carta {
  margin: 0;
}

.code-carta {
  margin: 2mm auto;
}

.font-pos-01 {
  font-size: 14px;
}

.font-pos-02 {
  font-size: 16px;
}

.font-pos-03 {
  font-size: 18px;
}

.font-pos-carta-01 {
  font-size: 36px;
}

.font-pos-carta-02 {
  font-size: 38px;
}

.font-pos-carta-03 {
  font-size: 40px;
}