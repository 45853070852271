.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.p-0, .pagination-item > a {
  padding: 0 !important;
}

.p-10 {
  padding: 10px !important;
}
.pt-2 {
  padding-top: 2px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.ptb-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}