body {
  margin: 0;
  padding: 0;
}

.form-label {
  display: block;
}

.navbar-link {
  white-space: nowrap;
}

.navbar-select {
  background-color: #343a40;;
  margin: 1px;
}

.nav-btn-white {
  color: #ffffff;
  border-color: #ffffff;
}

.white, .dropdown-item {
  color: #ffffff;
}

.b-green {
  background-color: #00aa00 !important;
}

.nav-btn-red {
  color: #ff0000;
  border-color: #ff0000;
}

.container-centered {
  justify-content: center;
  margin: 20px 0;
}

.mid-container {
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.full-container {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination-select {
  height: 36px;
  border: none;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.text-center {
  text-align: center;
}

.float-start {
  float: inline-start;
}

.float-end {
  float: inline-end;
}

.position-relative {
  position: relative;
}

.overlay-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.font-bold {
  font-weight: bold;
}

.font-inherit {
  font-size: inherit;
}

.react-datepicker-popper {
  z-index: 2;
}

.dropdown-menu {
  background-color: #212529;
}

.accordion-transparent > div {
  border: none;
}

.accordion-transparent > div > div {
  background-color: #ffffff;
}

.card-border-5 {
  border-width: 5px;
}